/* Global div:relative breaks Image component. This fixes that. */
.ant-image-preview-content {
    position: static !important;
}

/* There are some unwanted buttons on Image Zoom component. (rotate, close etc.) This hides them. */
.ant-image-preview-operations-operation:not(:first-child) {
    display: none;
}

/* Image component closes body scroll and change width of body. This blocks that.*/
.ant-scrolling-effect {
    overflow: hidden !important;
    overflow-y: scroll !important;
    width: unset !important;
}

/* Hiding label areas for ant design. Labels are necessery for HTML but not for UI. */
.ant-form-item-label {
    display: none !important;
}

/* Make all form items' width 100% */
.ant-popover-inner-content {
    padding: 1rem !important;
}

/* Hack dateRange !important values */
.ant-picker {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.ant-picker-active-bar {
    display: none;
}
.ant-picker-focused,
.ant-picker:hover {
    border-color: transparent !important;
    border-right-width: none !important;
    box-shadow: none;
    padding: 0 !important;
}
