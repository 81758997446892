// value   |0px     601px    961px    1441px   1921px    3841px
//        |--------|--------|--------|--------|---------|-------->
// key     |phone   tablet   desktop  large    uhd       uhd8

@mixin baseRem {
    @include freeBp(
        font-size,
        (
            (0, 320, 8.12px),
            (320, 478, 8.31px),
            (478, 767, 8.64px),
            (767, 1024, 8.95px),
            (1024, 1600, 9.2px),
            (1600, 1920, 9.3px),
            (1920, 2240, 9.4px),
            (2240, 2560, 10.5px),
            (2560, 2880, 11px),
            (2880, 3200, 12px),
            (3200, 3520, 13px),
            (3520, 3840, 14px),
            (3840, 4320, 15px),
            (4320, 4800, 16px),
            (4800, 5280, 17px),
            (5280, 5760, 18px),
            (5760, 6240, 19px),
            (6240, 6720, 20px),
            (6720, 7200, 21px),
            (3840, 7680, 22px)
        )
    );
}

$minAspRatio: "30/10";
$maxAspRatio: "5/10";

$bp: (
    phone: 0,
    tablet: 601,
    desktop: 961,
    large: 1441,
    uhd: 1921,
    uhd8: 3841,
);

// SINGLE BREAKPOINT
@mixin phone {
    @media (max-aspect-ratio: $maxAspRatio), (min-aspect-ratio: $minAspRatio), (max-width: #{map-get($bp, "tablet")}+"px") {
        @content;
    }
}
@mixin tablet {
    @media (min-aspect-ratio: $maxAspRatio) and (max-aspect-ratio: $minAspRatio) and (min-width: #{map-get($bp, "tablet")}+"px") 
    and (max-width: #{map-get($bp, "desktop")}+"px") {
        @content;
    }
}
@mixin desktop {
    @media (min-aspect-ratio: $maxAspRatio) and (max-aspect-ratio: $minAspRatio) and (min-width: #{map-get($bp, "desktop")}+"px") 
    and (max-width: #{map-get($bp, "large")}+"px") {
        @content;
    }
}
@mixin large {
    @media (min-aspect-ratio: $maxAspRatio) and (max-aspect-ratio: $minAspRatio) and (min-width: #{map-get($bp, "large")}+"px") 
    and (max-width: #{map-get($bp, "uhd")}+"px") {
        @content;
    }
}
@mixin uhd {
    @media (min-aspect-ratio: $maxAspRatio) and (max-aspect-ratio: $minAspRatio) and (min-width: #{map-get($bp, "uhd")}+"px") 
    and (max-width: #{map-get($bp, "uhd8")}+"px") {
        @content;
    }
}
@mixin uhd8 {
    @media (min-aspect-ratio: $maxAspRatio) and (max-aspect-ratio: $minAspRatio) and (min-width: #{map-get($bp, "uhd8")}+"px") {
        @content;
    }
}

// MULTIPLE BREAKPOINTS
@mixin smallScreens {
    @media (max-width: #{map-get($bp, "desktop")}+"px") {
        @content;
    }
}
@mixin largeScreens {
    @media (min-width: #{map-get($bp, "desktop")}+"px") {
        @content;
    }
}

@mixin media($keys...) {
    @each $key in $keys {
        @if ($key == phone) {
            @include phone {
                @content;
            }
        }
        @if ($key == tablet) {
            @include tablet {
                @content;
            }
        }
        @if ($key == desktop) {
            @include desktop {
                @content;
            }
        }
        @if ($key == large) {
            @include large {
                @content;
            }
        }
        @if ($key == uhd) {
            @include uhd {
                @content;
            }
        }
        @if ($key == uhd8) {
            @include uhd8 {
                @content;
            }
        }
        @if ($key == largeScreens) {
            @include largeScreens {
                @content;
            }
        }
        @if ($key == smallScreens) {
            @include smallScreens {
                @content;
            }
        }
    }
}

// SINGLE PROP
@mixin singleKey($key, $phone, $tablet, $desktop, $large, $uhd, $uhd8) {
    @include phone {
        #{$key}: $phone;
    }
    @include tablet {
        #{$key}: $tablet;
    }
    @include desktop {
        #{$key}: $desktop;
    }
    @include large {
        #{$key}: $large;
    }
    @include uhd {
        #{$key}: $uhd;
    }
    @include uhd8 {
        #{$key}: $uhd8;
    }
}
@mixin screenSize($prop, $smallScreens, $largeScreens) {
    @include smallScreens {
        #{$prop}: $smallScreens;
    }
    @include largeScreens {
        #{$prop}: $largeScreens;
    }
}
@mixin freeBp($prop, $obj) {
    @each $bps in $obj {
        @media (min-width: nth($bps, 1)+"px") and (max-width: nth($bps, 2)+"px") {
            #{$prop}: nth($bps, 3);
        }
    }
}

:export {
    minAspRatio: $minAspRatio;
    maxAspRatio: $maxAspRatio;

    @each $key, $value in $bp {
        #{unquote($key)}: $value;
    }
}
