@use "UIV2/base/scss/moduleCollector" as g;

.pageTitle {
    @include g.pageTitle;
}
.defaultText {
    @include g.defaultText;
}
.defaultSmall {
    @include g.defaultSmall;
}
.sectionTitle {
    @include g.sectionTitle;
}
.quote {
    @include g.quote;
}

.sectionName {
    @include g.fontWeight700;
    font-size: 1.8rem;
    color: getColor(grey50);
    position: relative;
    display: block;
}
.defaultTitle {
    @extend .defaultText;
    @include g.fontWeight700;
    margin-bottom: 1rem;
}
.highlightedTitle {
    font-size: 1.8rem;
    @include g.fontWeight400;
    color: g.getColor(primary);
}

.maxWidth60 {
    max-width: 60rem;
}
.maxWidth50 {
    max-width: 50rem;
}
.maxWidth40 {
    max-width: 40rem;
}
.maxWidth30 {
    max-width: 30rem;
}
