@import "./_colors.module.scss";
@import "./_breakpoints.module.scss";
// @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap");

@font-face {
    font-family: "Jakarta";
    font-weight: 300;
    src: url("./woff/jakarta_light.woff") format("woff");
    font-display: block;
}
@font-face {
    font-family: "Jakarta";
    font-weight: 400;
    src: url("./woff/jakarta_regular.woff") format("woff");
    font-display: block;
}
@font-face {
    font-family: "Jakarta";
    font-weight: 500;
    src: url("./woff/jakarta_medium.woff") format("woff");
    font-display: block;
}
@font-face {
    font-family: "Jakarta";
    font-weight: 700;
    src: url("./woff/jakarta_bold.woff") format("woff");
    font-display: block;
}
@font-face {
    font-family: "Jakarta";
    font-weight: 900;
    src: url("./woff/jakarta_extraBold.woff") format("woff");
    font-display: block;
}

// @mixin primaryFontFamily {
//     font-family: "Montserrat", "Arial", "Helvetica", "Verdana", sans-serif !important;
// }
@mixin primaryFontFamily {
    font-family: "Jakarta", "Arial", "Helvetica", "Verdana", sans-serif !important;
}
@mixin fontWeight300 {
    font-weight: 300;
}
@mixin fontWeight400 {
    font-weight: 400;
    @-moz-document url-prefix() {
        font-weight: 300;
    }
    @supports (-ms-ime-align: auto) {
        font-weight: 300;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        font-weight: 300;
    }
}
@mixin fontWeight500 {
    font-weight: 500;

    @-moz-document url-prefix() {
        font-weight: 300;
    }
    @supports (-ms-ime-align: auto) {
        font-weight: 300;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        font-weight: 300;
    }
}
@mixin fontWeight700 {
    font-weight: 700;

    @-moz-document url-prefix() {
        font-weight: 500;
    }
    @supports (-ms-ime-align: auto) {
        font-weight: 500;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        font-weight: 500;
    }
}
@mixin fontWeight900 {
    font-weight: 900;

    @-moz-document url-prefix() {
        font-weight: 700;
    }
    @supports (-ms-ime-align: auto) {
        font-weight: 700;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        font-weight: 700;
    }
}

// FONTS

@mixin pageTitle {
    @include primaryFontFamily;
    @include fontWeight300;
    font-size: 3.4rem;
    color: getColor(primary);
    margin: 5rem 0;
}
@mixin defaultText {
    @include primaryFontFamily;
    @include fontWeight500;
    font-size: 1.3rem;
    line-height: 1.9;
    color: getColor(primary);
}
@mixin defaultSmall {
    @include primaryFontFamily;
    @include fontWeight500;
    font-size: 1.2rem;
    line-height: 1.75;
}
@mixin sectionTitle {
    @include primaryFontFamily;
    @include fontWeight700;
    font-size: 1.4rem;
    color: getColor(grey60);
    margin-top: 6rem;
    margin-bottom: 6rem;
    letter-spacing: 0.3rem;
}
@mixin quote {
    @include primaryFontFamily;
    @include fontWeight300;
    font-size: 2.4rem;
    line-height: 1.75;
    max-width: 40rem;
    line-height: 1.5;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
