$colors: (
    // background
    "background": #f3f3f3,
    // primary
    "primary": #454545,
    "primaryShade": #101010,
    "primaryLight": #707070,
    // PREVIOUS ACCENTS
    // "accent": #135072,
    // "accentShade": #003553,
    // "accentSemiShade": #2d4d6e,
    // "accentExtraLight": #cee4ef,
    // "accentLight": #b6d3e3,
    // "accentSemiLight": #155980,
    // "antBlue": #e6f7ff,
    // NEW BLUE ACCENTS
    "secondary": #faad13,
    "accent": #0050ff,
    "accentShade": #003099,
    "accentSemiShade": #0040cc,
    "accentExtraLight": #ccdcff,
    "accentLight": #99b9ff,
    "accentSemiLight": #0050ff,
    "antBlue": #e6eeff,
    // Grey Tones
    "grey05": #f5f5f5,
    "grey10": #e5e5e5,
    "grey20": #cccccc,
    "grey30": #b2b2b2,
    "grey40": #999999,
    "grey50": #808080,
    "grey60": #666666,
    "grey70": #4d4d4d,
    "grey80": #333333,
    "grey90": #1a1a1a,
    // Semantic
    "error": #990000,
    "success": #00a436,
    // Stat Key Colors
    "clicks": #8b69f7,
    "conversions": #ff7487,
    "revenue": #c7a035,
    "events": #35b3c6,
    "epc": #1e7fcf
);

@function getColor($value) {
    @if map-has-key($colors, $value) {
        @return map-get($colors, $value);
    }
    @return map-get($colors, $primary);
}

:export {
    @each $key, $value in $colors {
        #{unquote($key)}: $value;
    }
}
