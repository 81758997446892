@use "UIV2/base/scss/moduleCollector" as g;

//-------------------------------------------------------------
// 	    HTML TAGS
//-------------------------------------------------------------

html {
    @include g.baseRem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    height: 100%;
    overflow-x: hidden;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        height: 100%;
        overflow: hidden;
    }
}

body {
    @include g.defaultText;
    background-color: g.getColor(background);
    padding: 0px;
    overflow-x: hidden;
    overflow-y: scroll;
    height: 100%;
    min-width: 320px;
    max-width: 3840px;
    margin: auto;
    line-height: 1.75;
}

#root {
    min-height: 100%;
    overflow: unset;
}

* {
    box-sizing: border-box;
}

button {
    @include g.primaryFontFamily;
    @include g.defaultText;
    margin: 0;
    padding: 0;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

input,
textarea {
    @include g.primaryFontFamily;
    @include g.defaultText;
}

p {
    margin: 0px;
    margin-bottom: 1rem;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0px;
}

div {
    position: relative;
    font-variant-ligatures: no-common-ligatures;
}

// Reset autofill styles for chrome
// input:-webkit-autofill,
// input:-webkit-autofill::first-line,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//     font-size: inherit;
//     font-family: inherit;
//     background-color: inherit;
//     color: inherit;
//     border: none;
//     -webkit-text-fill-color: inherit;
//     -webkit-box-shadow: 0 0 0px 1000px white inset;
//     box-shadow: 0 0 0px 1000px white inset;
// }
