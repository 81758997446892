@use "UIV2/base/scss/moduleCollector" as g;

.ThumbVertical {
    border-radius: 5rem;
    background-color: g.getColor(background);
    cursor: pointer;
    height: 0.5rem;

    &.light {
        background-color: g.getColor(accent);
    }
}

.TrackVertical {
    border-radius: 5rem;
    background-color: g.getColor(grey70);
    cursor: pointer;
    top: 2rem;
    right: 1rem;
    height: calc(100% - 4rem);

    &.light {
        background-color: g.getColor(grey20);
    }
}

.ThumbVerticalHome {
    border-radius: 5rem;
    background-color: g.getColor(accent);
    cursor: pointer;
    height: 0.5rem;

    &.light {
        background-color: g.getColor(accent);
    }
}

.TrackVerticalHome {
    border-radius: 5rem;
    background-color: g.getColor(grey30);
    cursor: pointer;
    top: 0rem;
    right: 0rem;
    height: 100%;

    &.light {
        background-color: g.getColor(grey20);
    }
}
