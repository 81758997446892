.fadeInUp {
    animation-name: fadeInUp;
    animation-duration: 2s;
}
@keyframes fadeInUp {
    0% {
        opacity: 0;
        transform: translate3d(0, 1rem, 0);
    }
    100% {
        opacity: 1;
        transform: none;
    }
}
