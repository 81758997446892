.antDropdownClass {
    font-family: Jakarta, Roboto, "Helvetica Neue", Arial;
    min-width: 200px;
    padding: 0;

    & > div > .rc-virtual-list > .rc-virtual-list-holder > div > .rc-virtual-list-holder-inner > div {
        padding-left: 2.8rem;
        font-size: 1.3rem;
    }
}
